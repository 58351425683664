<template>

    <div id='contact' class='flex justify-between flex-col md:flex-row pt-16 pb-56 px-6 md:px-20 gap-8 md:gap-16 relative' >
        <div class="flex-1 w-full" data-aos="zoom-in">
            <p class='text-[29px] lg:text-[34px] font-bold  text-[#0e2258] pb-2'>Contactez-Nous</p>

            <h1 class='text-[17px] lg:text-[18px] font-bold text-purple-800 py-2'>Si vous avez des questions ou besoin d'aide, veuillez remplir le formulaire ci-dessous. Nous faisons de notre mieux pour vous répondre dans un délai d'un jour ouvrable.</h1>
            <div class='py-6'>
                <input type="text" v-model="nom" placeholder='Nom et Prénom' class="my-3 border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                <div class="input-group flex justify-between my-5">
                    <input type="text" v-model="email" placeholder='Email' class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 w-[48%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                    <input type="text" v-model="telephone" placeholder='Téléphone' class="border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500  w-[48%] p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"/>
                </div>
                <textarea v-model="message" id="" placeholder='Message ..' rows="4" class="block p-2.5 w-full text-sm text-gray-900  border focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 my-5 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" ></textarea>
                <button @click="envoyer()" class='bg-cyan-500 px-8 py-4 rounded-full text-white'>Envoyer</button>
            </div>

        </div>
        <div data-aos="zoom-in" class="flex-1 boxss self-start w-full md:max-w-[500px] rounded-lg bg-white z-30 gap-y-16 md:gap-0 md:h-[500px] px-12 py-16 justify-between flex flex-col">
            <div class="flex justify-start gap-x-5 items-start">
                <font-awesome-icon icon="fa-solid fa-map" size=50 />
                    <div class="detail text-gray-600 font-[400] ">
                        <p class='text-[17px] lg:text-[20px] font-bold  text-[#0e2258]'>Adresse</p>
                        <p>GTHCONSULT, BOULEVARD MOHAMMED VI, LOT AL MASSIRA, LOT N°187, APPT 5-6 3EME ETAGE,</p>
                        <p>Mohammédia 28820 Maroc</p>
                    </div>
            </div>
            <div class="flex justify-start gap-x-5 items-start">
                <font-awesome-icon icon="fa-solid fa-phone" class="class='text-[#3b5aad] map'" size=50 />
                    <div class="detail text-gray-600 font-[400] ">
                        <p class='text-[17px] lg:text-[20px] font-bold  text-[#0e2258]'>Téléphone</p>
                        <p>+212 5 23 27 27 74</p>
                        <p>+212 5 23 27 29 79</p>
                    </div>
            </div>
            <div class="flex justify-start gap-x-5 items-start">
                <font-awesome-icon icon="fa-solid fa-envelope" />
                    <div class="detail text-gray-600 font-[400] ">
                        <p class='text-[17px] lg:text-[20px] font-bold  text-[#0e2258]'>Email</p>
                        <p>contact@gthconsult.ma </p>
                        <p>service.supports@gthconsult.ma</p>
                    </div>
            </div>
        </div>
        <img src='https://startup-agency-nextjs-five.vercel.app/_next/image?url=%2Fassets%2F9.png&w=1080&q=75' width=1000 height=400 class='absolute bottom-0 right-0 -z-10 w-[650px]'/>

        <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>

    </div>
</template>

<script>

import Messages from "@/connection/Messages"
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
    name : "ContactHome",
    data() {
        return {
                isLoading: false,
                nom :null,
                email: null,
                telephone : null,
                message : null
        }
    },

    components : {
        Loading
    },

    methods : {
        envoyer() {
            this.isLoading = true;
            Messages.envoyer(this.nom, this.email, this.telephone, this.message)
            .then((result) => {

                if(result.data.status == "succes") {
                    this.nom = null;
                    this.email=  null;
                    this.telephone =  null;
                    this.message =  null;
                    this.isLoading = false;
                }
                
            })
            .catch((error) => {
                console.log(error);
            });
        }
    }


}
</script>

<style scoped>

*{
    font-family: Cairo,Verdana,Tahoma,Arial;
  }

  .boxs{
    box-shadow: 0 0 1.5625rem 0 rgb(54 186 254 / 10%);
}

.boxss{
    box-shadow:0 0 1rem 10px rgb(54 186 254 / 10%)
}

 svg{
    font-size: 50px;
    color:rgb(36, 48, 100);
}

</style>