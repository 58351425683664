<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">

                <header class="modal-header">
                    <slot name="header">
                        Accorder l'accès de les interlocuteur ?
                    </slot>
                </header>

                <section class="modal-body">
                    <ul>
                        <li v-for="interlocuteur in interlocuteurs" :key="interlocuteur._id">
                            <input type="checkbox" :value="interlocuteur.email" v-model="selectedInterlocuteur">
                            <label>{{ interlocuteur.nom }} {{ interlocuteur.prenom }}</label>
                        </li>
                    </ul>
                </section>

                <footer class="modal-footer">
                    <button @click="validerAccess">Valider</button>
                    <button @click="closeAccess">Annuler</button>
                </footer>

            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ConfirmationModel',
    data() {
        return {
            selectedInterlocuteur: []
        }
    },

    props: {
        interlocuteurs: Array,
        idAccess: String
    },


    methods: {

        validerAccess() {
            this.$emit('validerAccess', this.selectedInterlocuteur, this.idAccess);
        },

        closeAccess() {
            console.log("close")
            this.$emit('closeAccess');
        }

    },

    created() {

    }

};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

}

.modal {
    background: #FFFFFF;
    min-width: 500px;
    max-width: 600px;
    max-height: 400px;
    overflow: auto;
}

.modal-header {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #fd0d0d;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
}



.modal-body {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
    font-size: 18px;
    width: 100%;
}

.modal-body ul  {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.modal-body ul li  {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.modal-body ul li input{
    margin-right: 5px;
}




.modal-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.modal-footer>button:nth-child(1) {
    border: 0px;
    font-size: large;
    width: 100px;
    background-color: green;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 25px;
}

.modal-footer>button:nth-child(2) {
    border: 0px;
    font-size: large;
    width: 100px;
    background-color: red;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 25px;
}
</style>