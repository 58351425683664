import { createRouter, createWebHistory  } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import ErrorView from '../views/ErrorView.vue'
import OffreEmploiView from '../views/OffreEmploiView.vue'
import EspaceClientView from '../views/EspaceClientView'
import DashboardView from '../views/DashboardView'
import TransferClientView from '../views/TransferClientView.vue'
import TransferDashboardView from '../views/TransferDashboardView.vue'
import CatalogueView from '../views/CatalogueView'
import CrvView from '../views/metiers/CrvView'
import ErtView from '../views/metiers/ErtView'
import FtView from '../views/metiers/FtView'
import AatView from '../views/metiers/AatView'
import EView from '../views/metiers/EView'
import ActualitesView from '../views/ActualitesView'
import SpontaneCandidature from '../views/SpontaneCandidature'
import AproposView from '../views/AproposView'
import NosValeursView from '../views/NosValeursView'
import NosMoyensView from '../views/NosMoyensView'
import EngagementView from '../views/EngagementView'
import RaisonsView from '../views/RaisonsView'
import NewsReglementation from '../views/NewsReglementation'
import PolitiqueView from '../views/PolitiqueView'
import MentionsLegalesView from '../views/MentionsLegalesView'
import PlanSiteView from '../views/PlanSiteView'
import DemandeDevisView from '../views/DemandeDevisView'
import OffreEmploisDetailsView from '../views/OffreEmploisDetailsView'
import ActualitesDetailsView from '../views/ActualitesDetailsView'
import FormationView from '../views/FormationView'
import EnqueteSatisfaction from '../views/EnqueteSatisfaction'
import ElearningView from '../views/ElearningView'
import ItView from '../views/ItView'
import DemandeView from '../views/DemandeView'
import CraalPage from '../components/componentsCrv/CraalPage'
import CrapgvPage from '../components/componentsCrv/CrapgvPage'
import CrdmecPage from '../components/componentsCrv/CrdmecPage'
import CrdsiPage from '../components/componentsCrv/CrdsiPage'
import CriePage from '../components/componentsCrv/CriePage'
import CetPage from '../components/componentsCrv/CetPage'
import CraemtrtPage from '../components/componentsCrv/CraemtrtPage'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorView
  },
  {
    path: '/offre-emploi',
    name: 'offre-emploi',
    component: OffreEmploiView
  },
  {
    path: '/espace-client',
    name: 'espace-client',
    component: EspaceClientView
  },
  {
    path: '/catalogue',
    name: 'catalogue',
    component: CatalogueView
  },
  {
    path: '/controle-reglementaire-et-volontaire',
    name: '/controle-reglementaire-et-volontaire',
    component: CrvView
  },
  {
    path: '/evaluation-des-risques-au-travail',
    name: '/evaluation-des-risques-au-travail',
    component: ErtView
  },
  {
    path: '/prelevements-environnementaux',
    name: '/prelevements-environnementaux',
    component: EView
  },
  {
    path: '/formations-techniques',
    name: '/formations-techniques',
    component: FtView
  },
  {
    path: '/audits-et-assistance-techniques',
    name: '/audits-et-assistance-techniques',
    component: AatView
  },
  {
    path: '/actualites',
    name: '/actualites',
    component: ActualitesView
  },
  {
    path: '/dashboard',
    name: '/dashboard',
    component: DashboardView
  },
  {
    path: '/candidature-spontanee',
    name: '/candidature-spontanee',
    component: SpontaneCandidature
  },
  {
    path: '/a-propos',
    name: '/a-propos',
    component: AproposView
  },
  {
    path: '/nos-valeurs',
    name: '/nos-valeurs',
    component: NosValeursView
  },
  {
    path: '/engagement',
    name: '/engagement',
    component: EngagementView
  },
  {
    path: '/4-raisons-de-nous-rejoindre',
    name: '/4-raisons-de-nous-rejoindre',
    component: RaisonsView
  },
  {
    path: '/news-réglementation',
    name: '/news-réglementation',
    component: NewsReglementation
  },
  {
    path: '/politique-de-confidentialite',
    name: '/politique-de-confidentialite',
    component: PolitiqueView
  },
  {
    path: '/mentions-legales-2',
    name: '/mentions-legales-2',
    component: MentionsLegalesView
  },
  {
    path: '/plan-du-site',
    name: '/plan-du-site',
    component: PlanSiteView
  },
  {
    path: '/demande-devis/:titre',
    name: '/demande-devis',
    component: DemandeDevisView
  },
  {
    path: '/nos-moyens',
    name: '/nos-moyens',
    component: NosMoyensView
  },
  {
    path: '/offre-emplois-details/:jobId',
    name: '/offre-emplois-details',
    component: OffreEmploisDetailsView
  },
  {
    path: '/actualites/:titre',
    name: '/actualites',
    component: ActualitesDetailsView
  },
  {
    path: '/demande',
    name: '/demande',
    component: DemandeView
  },
  {
    path: '/solution-it',
    name: '/solution-it',
    component: ItView
  },
  {
    path: '/formation',
    name: '/formation',
    component: FormationView
  },
  {
    path: '/controle-reglementaire-des-installations-electriques',
    name: '/controle-reglementaire-des-installations-electriques',
    component: CriePage
  },
  {
    path: '/controle-reglementaire-des-appareils-et-accessoires-de-levage',
    name: '/controle-reglementaire-des-appareils-et-accessoires-de-levage',
    component: CraalPage
  },
  {
    path: '/controle-reglementaire-des-machines-et-engins-de-chantier',
    name: '/controle-reglementaire-des-machines-et-engins-de-chantier',
    component: CrdmecPage
  },
  {
    path: '/controle-reglementaire-des-dispositifs-de-systeme-incendie',
    name: '/controle-reglementaire-des-dispositifs-de-systeme-incendie',
    component: CrdsiPage
  },
  {
    path: '/controle-reglementaire-des-appareils-a-pression-de-gaz-et-vapeur',
    name: '/controle-reglementaire-des-appareils-a-pression-de-gaz-et-vapeur',
    component: CrapgvPage
  },
  {
    path: '/controle-d-equipement-de-travail',
    name: '/controle-d-equipement-de-travail',
    component: CetPage
  },
  {
    path: '/controle-reglementaire-des-ascenseurs-escaliers-mecanique-trottoirs-roulantes',
    name: '/controle-reglementaire-des-ascenseurs-escaliers-mecanique-trottoirs-roulantes',
    component: CraemtrtPage
  },
  {
    path: '/enquete-de-satisfaction/:pathUrl',
    name: 'enqueteSatisfaction',
    component: EnqueteSatisfaction
  },
  {
    path: '/elearning',
    name: 'elearning',
    component: ElearningView
  },
  {
    path: '/transfer',
    name: 'transfer',
    component: TransferClientView
  },
  {
    path: '/transfer-dashboard',
    name: 'transfer-dashboard',
    component: TransferDashboardView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


export default router
