<template>

  <div class="dashboard">

    <div class="navbar">

      <div>
        <img :src="url" />
      </div>

      <ul>
        <li>{{ nomSociete }}</li>
        <li>{{ adresse }} {{ pays }}</li>
      </ul>

      <ul>
        <li>{{ nom }} {{ prenom }}</li>
        <li>{{ email }}</li>
      </ul>

      <ul>
        <li><button @click="deconnexion">Deconnexion</button></li>
      </ul>

    </div>

    <div class="content" v-if="!flagTableFirst">

      <div class="top">

        <!-- Espaces -->
        <p class="raison-social">Raison Social :</p>
        <select v-if="flagAdmin && flagEspace" v-model="espace" @change="selectEspace">
          <option v-for="(item, index) in espaces" :key="index" :value="item._id">{{ item.raisonSocial }}</option>
        </select>

        <button v-if="flagHandelDossier" @click="handelDossier">Créer un dossier</button>
        <button v-if="flagHandelDossier" @click="handelFichier">Uploder fichier(s)</button>

      </div>

      <div class="center">
        <ul>
          <li v-for="(parent, index) in listeParent" :key="index">
            <!-- Logo folder -->
            <p class="folder" v-if="parent.type == 'Dossier'"><i class="fa fa-folder"></i></p>
            <!-- logo file -->
            <p class="file" v-if="parent.type == 'Fichier'"><i class="fa fa-file"></i></p>
            <!-- Orginal Name -->
            <p>{{ parent.originalname }}</p>
            <P>| {{ `${parent.type} ${checkTypeFile(parent.originalname)}` }} |</P>
            <!-- Date create file -->
            <p>{{ new Date(parent.date).toLocaleDateString() }}</p>
            <!-- Apercu  -->
            <button class="apercu" v-if="parent.type == 'Dossier'" @click="voir(parent._id, index)"><i
                class="fa fa-arrow-right"></i>Aperçu</button>
            <!-- Télécharger -->
            <button class="telecharger" v-if="parent.type == 'Fichier'"
              @click="download(parent.filename, 'parent', parent.originalname)"><i
                class="fa fa-download"></i>Télécharger</button>
            <!-- Supprimer -->
            <button class="supprimer" v-if="parent.emailInterlocuteur == email || domain[1] == 'gthconsult.ma'"
              @click="supprimer(parent._id, parent.originalname, 'parent')"><i
                class="fa fa-trash"></i>Supprimer</button>
            <!-- Permission -->
            <button class="key" v-if="flagAdmin" @click="access(parent._id)"><i
                class="fa fa-key"></i>Permission</button>
            <!-- les access -->
            <p v-if="parent.partager.length != 0 && flagAdmin">{{ parent.partager }}</p>
          </li>
        </ul>

      </div>
    </div>

    <DeleteModel v-if="flagDeleteModel" :messageDelete="messageDelete" @validerDeleteModel="validerDeleteModel"
      @closeDeleteModel="closeDeleteModel" />
    <Access v-if="flagAccess" :interlocuteurs="interlocuteurs" :idAccess="idAccess" @validerAccess="validerAccess"
      @closeAccess="closeAccess" />
    <Create v-if="flagCreate" :type="type" @closeConfirmation="closeConfirmation"
      @validerConfirmationDossier="validerConfirmationDossier"
      @validerConfirmationFichier="validerConfirmationFichier" />
    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />
    <TableFirst v-if="flagTableFirst" :idParent="idParent" :titreParent="titreParent" :email="email"
      @retourPageParent="retourPageParent" />

  </div>
</template>

<script>
import Interlocuteur from "@/connection/Interlocuteur"
import Espace from "@/connection/Espace"
import Create from "@/components/models/Transfer/Create.vue"
import Access from "@/components/models/Transfer/Access.vue"
import TableFirst from "@/components/models/Transfer/TableFirst.vue"
import DeleteModel from "@/components/models/DeleteModel.vue"
import { useCookies } from "vue3-cookies";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  data() {
    return {
      flagDeleteModel: false,
      messageDelete: "",
      idDeleteModel: null,
      originalnameDeleteModel: null,
      valueDeleteModel: null,
      isLoading: false,
      adresse: null,
      email: null,
      emailCompany: null,
      nom: null,
      nomSociete: null,
      pays: null,
      prenom: null,
      url: null,
      ville: null,
      flagCreate: false,
      listeParent: [],
      type: null,
      flagTableFirst: false,
      idParent: null,
      titreParent: null,
      flagInterlocuteur: false,
      preInterlocuteurs: [],
      interlocuteurs: [],
      interlocuteur: null,
      flagEspace: false,
      espaces: [],
      espace: null,
      flagHandelDossier: false,
      flagHandelFichier: false,
      flagAccess: false,
      idAccess: null,
      flagAdmin: false,
      domain: null
    };
  },

  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },

  components: {
    Loading,
    Create,
    TableFirst,
    Access,
    DeleteModel
  },

  computed: {

  },

  methods: {

    closeDeleteModel() {
      this.flagDeleteModel = false;
    },

    validerDeleteModel() {
      console.log("Delete Model")
      Espace.supprimer(this.idDeleteModel, this.originalnameDeleteModel, this.valueDeleteModel)
        .then((result) => {
          if (result == true) {
            console.log(result)
            const index = this.listeParent.findIndex((el) => el._id == this.idDeleteModel)
            console.log(index)
            this.listeParent.splice(index, 1);
            this.flagDeleteModel = false;
            this.messageDelete = "";
            this.idDeleteModel = null;
            this.originalnameDeleteModel = null;
            this.valueDeleteModel = null;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    checkTypeFile(value) {

      const extention = value.split(".")

      if (extention[1] == "pdf") {
        return "Pdf";
      }

      else if (extention[1] == "txt") {
        return "Txt";
      }

      else if (extention[1] == "docx") {
        return "Word";
      }

      else if (extention[1] == "doc") {
        return "Word";
      }

      else if (extention[1] == "rar") {
        return "Rar"
      }

      else if (extention[1] == "pptx") {
        return "pptx";
      }

      else if (extention[1] == "ppt") {
        return "Power point";
      }

      else if (extention[1] == "png") {
        return "Image png";
      }

      else if (extention[1] == "mp4") {
        return 'video mp4';
      }

      else if (extention[1] == "jpeg") {
        return "Image Jpeg";
      }

      else if (extention[1] == "jpg") {
        return "Image Jpeg";
      }

      else {
        return "";
      }

    },

    selectEspace($event) {
      this.isLoading = true;
      this.interlocuteurs = [];
      this.listeParent = [];
      this.flagHandelDossier = true;
      this.flagHandelFichier = true;

      for (let i = 0; i < this.preInterlocuteurs.length; i++) {
        if (this.preInterlocuteurs[i].clientId == $event.target.value) {
          this.interlocuteurs.push(this.preInterlocuteurs[i])
        }
      }

      this.flagInterlocuteur = true;

      const index = this.espaces.findIndex((el) => el._id == $event.target.value);

      this.emailCompany = this.espaces[index].email;

      console.log(this.emailCompany)

      // Get my files or folders
      Espace.readParent(this.emailCompany)
        .then((result) => {

          if (result.data.listeParent.length == 0) {
            this.isLoading = false;
          }

          if (result.data.listeParent.length != 0) {
            for (let i = 0; i < result.data.listeParent.length; i++) {
              result.data.listeParent[i].status = false;
              this.listeParent.push(result.data.listeParent[i]);
            }
            this.isLoading = false;
          }
        })
        .catch((error) => {
          console.log(error)
        });
    },

    supprimer(id, originalname, value) {
        this.messageDelete = "Êtes-vous sûr de vouloir supprimer cet élément ?",
        this.idDeleteModel = id;
        this.originalnameDeleteModel = originalname;
        this.valueDeleteModel = value;
        this.flagDeleteModel = true;
    },



    downloadFile(response, title, typeContent) {

      var newBlob = new Blob([response.data], {
        type: typeContent
      });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = title;
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);

    },

    download(filename, value, originalname) {

      const extention = originalname.split('.').pop();

      var typeContent = null;

      if (extention == "pdf") {
        typeContent = "application/pdf";
      }

      if (extention == "txt") {
        typeContent = "text/plain";
      }

      if (extention == "docx") {
        typeContent = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      }

      if (extention == "doc") {
        typeContent = "application/msword";
      }

      if (extention == "rar") {
        typeContent = "application/vnd.rar";
      }

      if (extention == "pptx") {
        typeContent = "application/vnd.openxmlformats-officedocument.presentationml.presentation";
      }

      if (extention == "ppt") {
        typeContent = "application/vnd.ms-powerpoint";
      }

      if (extention == "png") {
        typeContent = "image/png";
      }

      if (extention == "mp4") {
        typeContent = "video/mp4";
      }

      if (extention == "jpeg") {
        typeContent = "image/jpeg";
      }

      if (extention == "jpg") {
        typeContent = "image/jpeg";
      }

      Espace.download(filename, value)
        .then((result) => {
          this.downloadFile(result, originalname, typeContent);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    access(id) {
      this.idAccess = id;
      this.flagAccess = true;
    },

    validerAccess(interlocuteurs, idAccess) {


      Espace.validerAccess(interlocuteurs, idAccess)
        .then((result) => {
          if (result) {
            const index = this.listeParent.findIndex((el) => el._id == idAccess)
            this.listeParent[index].partager = [];
            this.listeParent[index].partager = interlocuteurs;
            this.flagAccess = false;
          }
        })
        .catch((error) => {
          console.log(error.message)
        });

    },

    closeAccess() {
      this.flagAccess = false;
    },


    retourPageParent() {
      this.flagTableFirst = false;
    },

    voir(id, index) {
      this.titreParent = this.listeParent[index].originalname;
      this.idParent = id;
      this.flagTableFirst = true;
    },


    delete(id) {
      Espace.delete(id)
        .then((result) => {
          if (result.data) {
            const index = this.myFiles.findIndex((el) => el._id == id);
            this.myFiles.splice(index, 1);
          }
        })
        .catch((error) => {
          console.log(error.message)
        });
    },

    closeConfirmation() {
      this.type = null;
      this.flagCreate = false;
    },

    // valider dossier
    async validerConfirmationDossier(name) {

      this.type = null;
      this.flagCreate = false;
      this.isLoading = true;

      if (this.flagAdmin) {

        Espace.soumetterDossier(this.emailCompany, name, this.email)
          .then((result) => {
            if (result) {
              this.listeParent.unshift(result.data);
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log(error.message)
          });

      } else {

        Espace.soumetterDossier(this.emailCompany, name, this.email)
          .then((result) => {
            if (result) {
              // after create add acces valide by email
              this.listeParent.unshift(result.data);
              this.validerAccess([this.email], result.data._id);
              this.isLoading = false;
            } else {
              this.isLoading = false;
            }
          })
          .catch((error) => {
            console.log(error.message)
          });
      }

    },

    // valider fichier
    validerConfirmationFichier(document) {

      this.type = null;
      this.flagCreate = false;
      this.isLoading = true;

      if (this.flagAdmin) {

        Espace.soumetterFichier(this.emailCompany, document, this.email)
          .then((result) => {
            for (let i = 0; i < result.data.length; i++) {
              this.listeParent.unshift(result.data[i]);
            }
            result.data = [];
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error.message)
          });

      } else {

        Espace.soumetterFichier(this.emailCompany, document, this.email)
          .then((result) => {
            for (let i = 0; i < result.data.length; i++) {
              this.listeParent.unshift(result.data[i]);
              this.validerAccess([this.email], result.data[i]._id);
            }
            result.data = [];
            this.isLoading = false;
          })
          .catch((error) => {
            console.log(error.message)
          });
      }



    },

    handelDossier() {
      this.type = "dossier";
      this.flagCreate = true;
    },

    handelFichier() {
      this.type = "fichier";
      this.flagCreate = true;
    },

    deconnexion() {
      this.cookies.remove("accessToken");
      this.cookies.remove("refreshToken");
      return this.$router.push("/transfer");
    },


  },
  mounted() {

    if (this.cookies.get("accessToken") == null || this.cookies.get("accessToken") == "") {
      return this.$router.push("/transfer");
    } else {

      Interlocuteur.profile(this.cookies.get("accessToken"))
        .then((result) => {

          // Get info auth
          this.adresse = result.adresse;
          this.email = String(result.email);
          this.nom = result.nom;
          this.prenom = result.prenom;
          this.nomSociete = result.nomSociete;
          this.pays = result.pays;
          this.url = result.url;

          // check admin or user
          this.domain = this.email.split("@");

          if (this.domain[1] == "gthconsult.ma") {

            this.flagEspace = true;
            this.flagAdmin = true;

            // get Espaces
            Espace.read(this.cookies.get("accessToken"))
              .then((result) => {
                this.espaces = result;

              })
              .catch((error) => {
                console.log(error)
              });


            // get interlocuteurs
            Interlocuteur.read(this.cookies.get("accessToken"))
              .then((result) => {
                this.preInterlocuteurs = result;
              })
              .catch((error) => {
                console.log(error)
              });


          } else {

            this.flagEspace = true;
            this.flagAdmin = false;

            // Get folder and files
            Espace.readAccess(this.email)
              .then((result) => {
                this.listeParent = result.data.listeParent;
                this.emailCompany = result.data.email;
                this.flagHandelDossier = true;
                this.flagHandelFichier = true;
              })
              .catch((error) => {
                console.log(error)
              });
          }

        })
        .catch((error) => {
          console.log(error);
        });




    }

  }
};
</script>

<style scoped>
/* Start page */
* {
  font-family: Cairo, Verdana, Tahoma, Arial;
}

.dashboard {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

/* End page */


/* Start Navbar */
.navbar {
  padding: 10px;
  margin: 0px;
  background-color: #e7e7e7c7;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: auto;
}

.navbar div img {
  height: 60px;
}

.navbar ul:nth-child(2) {
  padding: 15px;
  border: 1px solid white;
}

.navbar ul:nth-child(3) {
  padding: 15px;
  border: 1px solid #0057e3;
}

.navbar ul li {
  color: black;
}

.navbar ul li button {
  background-color: #0057e3;
  color: white;
  padding: 15px;
  border-radius: 25px;
  font-size: large;
  border: 0px;
}

.navbar ul li button:hover {
  background-color: #0665ff;
}

/* End Navbar */

/* Start conetnt */
.content {
  height: auto;
  width: 100%;
  padding: 0;
  margin: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
}

/* Fin content */


/* Content Top */
.content .top {
  width: 100%;
  padding: 10px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
  -moz-box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
  box-shadow: 5px 7px 36px 3px rgba(66, 68, 90, 1);
}

.raison-social {
  color: #0057e3;
}

.content .top select {
  border-radius: 25px;
  font-size: large;
  margin-left: 5px;
  margin-right: 5px;
  color: #0057e3;
  outline: 1px solid #0057e3;
  padding: 10px;
  background-color: white;
}

.content .top button {
  border-radius: 25px;
  font-size: large;
  margin-left: 5px;
  margin-right: 5px;
  color: #0057e3;
  outline: 1px solid #0057e3;
  padding: 10px;
  background-color: white;
}

.content .top button:hover {
  background-color: rgba(224, 224, 224, 0.808);
}

/* End content Top */


/* Start content center */
.content .center {
  height: auto;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.content .center ul {
  height: auto;
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.content .center ul li {
  width: 100%;
  padding: 5px;
  outline: 1px solid #0057e3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  border-radius: 50px;
  border-radius: 50px;
  border-radius: 25px;
  background: white;
  cursor: pointer;
}

.content .center ul li {
  width: 100%;
  height: 40px;
  padding: 5px;
  outline: 1px solid #0057e3;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
}

.content .center ul li p,
.content .center ul li button {
  margin-left: 20px;
}

.apercu,
.telecharger {
  color: white;
  background-color: #348334;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
}

.apercu:hover,
.telecharger:hover {
  background-color: green
}

.key {
  color: black;
  background-color: #e0d319;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;

}

.key:hover {
  background-color: #ffee00;
}

.supprimer {
  color: white;
  background-color: #d31111b0;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 25px;
}

.supprimer:hover {
  background-color: red;
}


.fa {
  margin-right: 5px;
}

.folder i {
  color: #c35c00;
}

.file i {
  color: #4459c5;
}
</style>
