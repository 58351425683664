<template>
    <div class="espace-client">
      <div class="content">
        <LoginTransferClient />
      </div>
    </div>
  </template>
  <script>
  
  import LoginTransferClient from '@/components/componentsTransferClient.vue/LoginTransferClient.vue'
  
  export default {
    name: "EspaceClientView",
    components : {
        LoginTransferClient
    }
  
  }
  </script>
  
  <style scoped>
  
  .espace-client {
    margin: 0;
    padding: 0;
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url("https://actualites.fra1.cdn.digitaloceanspaces.com/data-center.jpg");
    background-size: cover;
    background-position: center;
  }
  
  .espace-client .content {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgb(21 9 118 / 38%);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  </style>