<template>
    <transition name="modal-fade">
        <div class="modal-backdrop">
            <div class="modal">

                <header class="modal-header">
                    <slot v-if="type == 'fichier'" name="header">
                        Entrer le(s) fichier(s)
                    </slot>
                    <slot v-if="type == 'dossier'" name="header">
                        Entrer le dossier
                    </slot>
                    <button type="button" class="btn-close" @click="closeConfirmation">
                        x
                    </button>
                </header>

                <section v-if="type == 'dossier'" class="modal-body">
                    <input type="text" v-model="name" placeholder="Entrez le nom du dossier">
                </section>

                <section v-if="type == 'fichier'" class="modal-body">
                    <input type="file" ref="file" @change="onFileChange($event)" multiple /> 
                </section>

                <footer class="modal-footer">
                    <button type="button" class="btn-green" @click="validerConfirmation">
                        Oui
                    </button>
                    <button type="button" class="btn-red" @click="closeConfirmation">
                        Non
                    </button>
                </footer>

            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'ConfirmationModel',
    data() {
        return {
            name: "",
            document : null
        }
    },

    props: {
        type: String
    },


    methods: {

        onFileChange(e) {
            this.document = e.target.files;
        },

        closeConfirmation() {
            this.document = null;
            this.$emit('closeConfirmation');
        },

        validerConfirmation() {

            if (this.name != "" && this.name != null && this.type == "dossier") {
                this.$emit('validerConfirmationDossier', this.name, this.type);
            }

            if (this.type == "fichier") {
                if(this.document != null) {
                    this.$emit('validerConfirmationFichier', this.document);
                }
            }
        }

    }
};
</script>

<style scoped>
.modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;

}

.modal {
    background: #FFFFFF;
    min-width: 500px;
    max-width: 600px;
    max-height: 400px;
    overflow: auto;
}

.modal-header,
.modal-footer {
    padding: 15px;
    display: flex;
}

.modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #fd0d0d;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
}

.modal-footer {
    border-top: 1px solid #eeeeee;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal-body {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    font-size: 18px;

}

.modal-body input {
    outline: 1px solid #003d9e;
    height: 50px;
    border-radius: 25px;
    padding: 5px;
}


.btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #4AAE9B;
    background: transparent;
}

.modal-footer>.btn-green {
    border: 0px;
    font-size: large;
    width: 100px;
    background-color: green;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 25px;
}

.modal-footer>.btn-red {
    border: 0px;
    font-size: large;
    width: 100px;
    background-color: red;
    color: white;
    padding: 10px;
    cursor: pointer;
    margin-left: 5px;
    border-radius: 25px;
}
</style>