<template>
  <div class="dashboard">

    <div class="top  pr-4  pl-4">
      <div class="logo w-24 mr-6">
        <img :src="url" />
      </div>

      <div class="infoHeader">
      <div class="company-name  text-zinc-400 text-left text-sm">
       {{ nomSociete }} <br>
       {{ adresse }}, {{  pays }}
      </div>
      <div class="name   text-zinc-400 text-left text-sm">
        {{ nom }} {{ prenom }}  <br>
       {{ email }}
       </div>
      <div class="buttons flex gap-4 ">

        <button class="px-4 py-3 text-sm font-medium text-white bg-indigo-600 rounded-full hover:text-white"  @click="openModal">
          <font-awesome-icon :icon="['fas', 'envelope']" /> Observation/Reclamation
        </button>
      
        <ModalMessage v-if="isModalOpen" @quitter="closeModal" @envoyer="sendObservation"  />

         <button @click="deconnexion()" class="px-4 py-3 text-sm font-medium text-white bg-gray-600 rounded-full hover:text-white">
          <font-awesome-icon :icon="['fas', 'right-from-bracket']" /> Déconnexion
        </button>
        
        
      </div>
    </div>

      <div class=" affichemenu flex justify-center rounded-full  cursor-pointer filterr" @click="showDiv = true">
        <span class="fa fa-bars" id="icon"></span>
      </div>
  
      <div v-if="showDiv" class="fixed top-0 left-0 right-0 h-5/6 p-10 bg-zinc-300 z-50 ">
        <div class="pb-6">
          <div class="grid gap-10 text-center">
            <div class="company-name  text-zinc-400 text-left text-sm">
             {{ nomSociete }} <br>
             {{ adresse }}, {{ pays }}
            </div>
            <div class="name   text-zinc-400 text-left text-sm">
              {{ nom }} {{ prenom }}<br>
             {{ email }}
             </div>
            <div class="buttons grid gap-4 ">

              <button class="px-4 py-3 text-sm font-medium text-white bg-indigo-600 rounded-full hover:text-white"  @click="openModal">
                <font-awesome-icon :icon="['fas', 'envelope']" /> Observation/Reclamation
              </button>
             

               <ModalMessage v-if="isModalOpen" @quitter="closeModal" @envoyer="sendObservation"  />

             
             
               <button class="px-4 py-3 text-sm font-medium text-white bg-gray-600 rounded-full hover:text-white">
                <font-awesome-icon :icon="['fas', 'right-from-bracket']" /> Déconnexion
              </button>
              
              
            </div>
          </div>
         
        </div>
       
  
        <button @click="showDiv = false" class="ml-2"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16"> <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/> <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/> </svg></button>
      </div>
    </div>
    
    <div class="bottom">
      <div class="left p-6 pt-10  ">
       <div class="DownIndex">
        <h1 class="text-lg font-bold text-zinc-400 mb-3 mt-3 text-left">Trouver Le Rapport</h1>

        <div class="relative">
          <input type="text" @input="setReferenceRapports($event)" placeholder="Recherche" class="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 ">
          <span class="absolute inset-y-0 right-2 flex items-center pr-3">
            <svg class="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-5.2-5.2M15 11a4 4 0 1 1-8 0 4 4 0 0 1 8 0z"></path>
            </svg>
          </span>
        </div>

        <h1 class="text-lg font-bold text-zinc-400 mb-3 mt-4 text-left">Trouver La Catégorie</h1>

        <div class="relative inline-block w-full">
          <select @input="setTypeRapports($event)" class="appearance-none w-full px-4 py-2 text-zinc-400 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 ">
            <option v-for="item in categorie" :key="item">{{ item.name }}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path d="M10 12l-6-6h12l-6 6z" />
            </svg>
          </div>
        </div>

        <h1 class="text-lg font-bold text-zinc-400 mb-3 mt-4 text-left">Trouver de localisation</h1>

        <div class="relative inline-block w-full">
          <select @input="setLocalisation($event)" class="appearance-none w-full px-4 py-2 text-zinc-400 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 ">
            <option v-for="item in listeLocalisation" :key="item">{{ item }}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path d="M10 12l-6-6h12l-6 6z" />
            </svg>
          </div>
        </div>


        <h1 class="text-lg font-bold text-zinc-400 mb-3 mt-4 text-left">Trouver L'anneé</h1>

        <div class="relative inline-block w-full">
          <select v-model="rechercherAnnee" @input="setRechercherAnnee($event)" class=" appearance-none w-full px-4 py-2 text-zinc-400 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 ">
            <option v-for="item in liste" :key="item">{{ item }}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path d="M10 12l-6-6h12l-6 6z" />
            </svg>
          </div>
        </div>
       </div>
        
      </div>
      <div class="right p-6">
       
        <div class="content ">
          <div class="bg-white shadow-md rounded-lg overflow-x-auto">
            <table class="min-w-full divide-y divide-gray-200 ">
              <thead class="bg-gray-100">
                <tr>
                  <th class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase ">Date</th>
                  <th class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase w-64 ">Références de rapport | Localisation</th>
                  <th class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase w-64 ">Local</th>
                  <th class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase ">Désignation</th>
                  <th class="px-6 py-3 text-left text-xs font-bold text-gray-500 uppercase ">Action</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200 text-left text-cyan-500  font-medium">
             
                <tr class="hover:bg-zinc-100" v-for="item in filterRapports" :key="item.id">
                  <td class=" py-4 pl-4 whitespace-nowrap" :title="item.dateIntervention">{{ item.dateIntervention }}</td>
                  <td class=" py-4  pl-4 whitespace-nowrap" :title="item.referenceRapport">{{ item.referenceRapport }}</td>
                  <td class=" py-4  pl-4 whitespace-nowrap" :title="item.locationEditRapport">{{ item.locationEditRapport }}</td>
                  <td class=" py-4  pl-4 whitespace-nowrap" :title="item.designation">{{ item.designation.slice(0, 60) }}</td>
                  <td class=" py-4  pl-4 pr-10 whitespace-nowrap flex">
                    <div @click="displayRapport(item.filename)" class="px-4 py-2 text-sm font-medium text-white bg-indigo-300 rounded-full  cursor-pointer"> <p class="text-gray-700 animate-bounce-infinite "> <font-awesome-icon :icon="['fas', 'download']" />Télécharger</p></div>
                    <div class="px-4 py-2 ml-2 text-sm font-medium text-white bg-red-300 rounded-full cursor-pointer "><p class="text-gray-700 animate-spin-slow"><font-awesome-icon :icon="['fas', 'plus']" />Nouveau</p></div>
                  </td>
                </tr>
   
              </tbody>
            </table>
          </div>
          
        </div>
        <div class="footer flex flex-wrap items-baseline gap-10">
        <div class="flex justify-center mt-4">
          <nav class="inline-flex rounded-md shadow">
            <a href="#" @click="selectPageLeft" class="px-2 py-1 bg-gray-200 text-gray-500 rounded-l-md hover:bg-gray-300">précédent</a>
            <span v-for="n in numberPage" :key="n" @click="selectPage(n)">
              <a href="#" v-show="numberPageSelect[n-1] == false" class="px-2 py-1 bg-gray-200 text-gray-500 hover:bg-gray-300">{{ n }}</a>
              <a href="#" v-show="numberPageSelect[n-1] == true" class="px-2 py-1 bg-gray-200 text-bleu-500 hover:bg-blue-300">{{ n }}</a>
            </span>
            <a href="#" @click="selectPageRight" class="px-2 py-1 bg-gray-200 text-gray-500 rounded-r-md hover:bg-gray-300">suivant</a>
          </nav>
        </div>
        <div class="flex items-center mb-2">
          <label for="perPage" class="mr-2">afficher:</label>
          <select @change="pagination" v-model="itemsPerPage" id="perPage" class="px-2 py-1 border rounded-md">
            <option v-for="item in listeItemsPerPage" :key="item" :value="item">{{ item }}</option>
          </select>
        </div>
       
      </div>
      </div>
    </div>

    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage"/>

    
  </div>
</template>

<script>
import Interlocuteur from "@/connection/Interlocuteur"
import Rapports from "@/connection/Rapports"
import Observations from "@/connection/Observations"
import { useCookies } from "vue3-cookies";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import ModalMessage from "@/components/componentDashboard/ModalMessage.vue"



export default {
  data() {
      return {
        isLoading: false,
        fullPage: true,
        message : null,
        showDiv: false,
        isModalOpen: false,
        adresse : null,
        email : null,
        nom :null,
        nomSociete :null,
        pays : null,
        prenom : null,
        url :null,
        ville : null,
        rapports : [],
        preRapports : [],
        categorie : [
          { name : null, brev : null},
          { name : "Contrôle réglementations installations techniques", brev : "crit"},
          { name : "Contrôle des risques travailleur", brev : "crt"},
          { name : "Contrôle environnementaux", brev : "ce"},
          { name : "Formation reglementaires", brev : "fr"}
        ],
        liste :[],
        listeItemsPerPage : [10, 20, 30, 40, 50],
        pageIndex : 0,
        itemsPerPage : 10,
        numberPage: null,
        totalRapports : null,
        numberPageSelect: [],
        rechercherReferenceRapport : null,
        rechercherTypeRapport : null,
        rechercherLocalisation : null,
        rechercherAnnee : null,
        listeLocalisation : []
      };
  },

  setup() {
        const { cookies } = useCookies();
        return { cookies };
  },

  components : {
    Loading,
    ModalMessage
  },

  computed : {

    filterRapports() {
      if(!this.rechercherReferenceRapport && !this.rechercherAnnee && !this.rechercherTypeRapport && !this.rechercherLocalisation){
          return this.rapports.filter((item) => {
            return item;
          })

      } else {
        return this.preRapports.filter((item) => {
          
            if(item != undefined) {

              const referenceRapport = String(this.rechercherReferenceRapport).toLocaleLowerCase();
              const rechercherTypeRapport = String(this.rechercherTypeRapport).toLocaleLowerCase();
              const rechercherLocalisation = String(this.rechercherLocalisation).toLocaleLowerCase();

              return !item.referenceRapport.toLocaleLowerCase().toString().indexOf(referenceRapport) ||
                     !new Date(item.dateIntervention).getFullYear().toString().indexOf(this.rechercherAnnee) ||
                     !item.typeRapport.toLocaleLowerCase().toString().indexOf(rechercherTypeRapport) ||
                     !item.locationEditRapport.toLocaleLowerCase().toString().indexOf(rechercherLocalisation);

            }
        })
      }

    }

  },

  methods: {

    setRechercherAnnee(e) {
      this.rechercherLocalisation = null;
      this.rechercherReferenceRapport = null;
      this.rechercherTypeRapport = null;
      this.rechercherAnnee = e.target.value;
    },

    setLocalisation(e) {
      this.rechercherReferenceRapport = null;
      this.rechercherTypeRapport = null;
      this.rechercherAnnee = null;
      this.rechercherLocalisation = e.target.value;
    },

    setReferenceRapports(e) {
      this.rechercherReferenceRapport = e.target.value;
      this.rechercherTypeRapport = null;
      this.rechercherAnnee = null;
      this.rechercherLocalisation = null;
    },

    setTypeRapports(e) {
      this.rechercherReferenceRapport = null;
      this.rechercherTypeRapport = e.target.value;
      this.rechercherAnnee = null;
      this.rechercherLocalisation = null;
    },

    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },

    sendObservation(value) {
      this.isModalOpen = false;
      this.isLoading = true;
      Observations.envoyer(this.cookies.get("accessToken"), value)
      .then((result) => {
        if(result.status == "succes") {
          this.isLoading = false;
        }
      })
      .catch((error) => {
        console.log(error)
      });
    },

    deconnexion() {
      this.cookies.remove("accessToken");
      this.cookies.remove("refreshToken");
      return this.$router.push("/");  
    },

    displayRapport(filename) {
     
        this.isLoading = true;
        Rapports.displayRapport(filename)
        .then((result) => {
                if(result) {
                  this.isLoading = false;
                }

        })
        .catch((error) => {
          console.log(error);
        });
    },

    pagination() {

      this.rapports = [];
      for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
          this.rapports.push(this.preRapports[i]);
      }
    },

    selectPageLeft() {
        // get first page
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[0] = true;

        this.rapports = [];
        for(let i = 0 * this.itemsPerPage; i < (0 * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    selectPageRight() {

        // get first page
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        const indice = this.numberPage - 1;
        this.numberPageSelect[indice] = true;
        this.rapports = [];
        for(let i = indice * this.itemsPerPage; i < (indice * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }
    },

    selectPage(value) {

        const select = value - 1;
        // change current select for change color
        for(let i = 0; i < this.numberPage; i++) {
              this.numberPageSelect[i] = false;
        }

        this.numberPageSelect[select] = true;
        this.rapports = [];
        for(let i = select * this.itemsPerPage; i < (select * this.itemsPerPage) + this.itemsPerPage; i++) {
            this.rapports.push(this.preRapports[i]);
        }

    },


  },
  mounted() {

    

    if(this.cookies.get("accessToken") == null || this.cookies.get("accessToken") == "") {
          return this.$router.push("/espace-client");     
    } else {

      const date = new Date();
      for(let i = date.getFullYear(); i >= 2019; i--) {
        this.liste.push(i)
      }

      this.liste.unshift(null)

      Interlocuteur.profile(this.cookies.get("accessToken"))
      .then((result) => {

        this.adresse = result.adresse;
        this.email = result.email;
        this.nom = result.nom;
        this.prenom = result.prenom;
        this.nomSociete = result.nomSociete;
        this.pays = result.pays;
        this.url = result.url;

        // Get all Rapports
        Rapports.readRapports(this.cookies.get("accessToken"))
        .then((result) => {

               this.preRapports = result;

              //  for (const [i, value] of myArray.entries()) {
              //       console.log('%d: %s', i, value);
              //  }

              this.listeLocalisation.push("");

               for (const value of this.preRapports) {
                  if(!this.listeLocalisation.includes(value.locationEditRapport) && value.locationEditRapport != null && value.locationEditRapport != "") {
                    this.listeLocalisation.push(value.locationEditRapport);
                  }
               }

               //ADD VIDE


               this.numberPage = Math.ceil(result.length / this.itemsPerPage);
               for(let i = 0; i < this.numberPage; i++) {
                    this.numberPageSelect.push(false);
                }

                this.numberPageSelect[0] = true;
                for(let i = this.pageIndex * this.itemsPerPage; i < (this.pageIndex * this.itemsPerPage) + this.itemsPerPage; i++) {
                    this.rapports.push(this.preRapports[i]);
                }


        })
        .catch((error) => {
          console.log(error);
        });

      })
      .catch((error) => {
          console.log(error);
      });
    }

  }
};
</script>

<style scoped>
*{
  font-family: Cairo,Verdana,Tahoma,Arial;
}
.dashboard {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.infoHeader{
  display:flex;
  gap:30px;
}
.testModal{
  z-index: 110;
}
.DownIndex{
  z-index: -100;
}

.top {
  width: 100%;
  z-index: 40;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); 
}

.bottom {
  display: flex;
}
.affichemenu{
  display: none;
}
.left {
  width: 20%;
 z-index: 10;
  height: auto;
  min-height: 90vh;
  box-shadow:8px 0px 8px -4px rgba(0, 0, 0, 0.1);
}

.right {
  width: 80%;
  background-color: rgb(250, 252, 253);
}
.content{
 
  height: auto;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-1px);
  }
  100% {
    transform: translateY(1px);
  }
}

.animate-bounce-infinite {
  animation: bounce .7s infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin 2s linear infinite;
}

@media screen and (max-width : 1010px){
  .infoHeader{
    display: none;
  }
  .affichemenu{
    display: block;
  }
  .bottom{
   
   min-width: 100%;
    display:grid;
    justify-content: center;
   

  }
  .bottom .left{
    width:100%;
    box-shadow:none;
  }
  .bottom .left input{
    width: 100%;
  }
  .bottom .right{
    width: 100%;
    margin-top:-300px;
    overflow-x: auto;
  }
}
</style>
