import axios from 'axios'
import config from "@/config"

// class Devis
class MesFormations {

    //--------------------------------------- create formation --------------------------------------------
    static create(schemaFormation, schemaLecon, schemaProgramme, formateurId) {
        
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/mesformations/create`,
            {
                schemaFormation : schemaFormation,
                schemaLecon : schemaLecon,
                schemaProgramme : schemaProgramme,
                formateurId : formateurId
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static select(mesFormationId) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/mesformations/select`,
            {
                mesFormationId : mesFormationId,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }

    static selectMedias(filename) {
        return new Promise((resolve, reject) => {
            axios.post(`${config.launch}/mesformations/selectMedias`,
            {
                filename : filename,
            },
            {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
            .then(response => {
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
        })
    }

    static read() {
        return new Promise((resolve, reject) => {
            axios.get(`${config.launch}/mesformations/read`, {
                headers: {
                     'Content-Type': 'application/json'
                 }
             })
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        })
    }



    static delete(mesFormationId) {
        return new Promise((resolve, reject) => {
            axios.delete(`${config.launch}/mesformations/delete/${mesFormationId}`)
            .then(response => {
                    resolve(response);
            })
            .catch(error => {
                    reject(error);
            });
        })
    }


}


export default MesFormations;
