<template>

  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="stylesheet" href="../style.css">

    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  </head>

  <HeaderHome v-if="this.$router.currentRoute.value.path == '/' ||
    this.$router.currentRoute.value.path == '/a-propos' ||
    this.$router.currentRoute.value.path == '/catalogue' ||
    this.$router.currentRoute.value.path == '/offre-emploi' ||
    this.$router.currentRoute.value.path == '/candidature-spontanee' ||
    this.$router.currentRoute.value.path == '/actualites' ||
    this.$router.currentRoute.value.path == '/5-raisons-de-nous-rejoindre' ||
    this.$router.currentRoute.value.path == '/engagement' ||
    this.$router.currentRoute.value.path == '/nos-valeurs' ||
    this.$router.currentRoute.value.path == '/agrements' ||
    this.$router.currentRoute.value.path == '/nos-moyens' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-et-volontaire' ||
    this.$router.currentRoute.value.path == '/evaluation-des-risques-au-travail' ||
    this.$router.currentRoute.value.path == '/prelevements-environnementaux' ||
    this.$router.currentRoute.value.path == '/formations-techniques' ||
    this.$router.currentRoute.value.path == '/audits-et-assistance-techniques' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-installations-electriques' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-appareils-et-accessoires-de-levage' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-machines-et-engins-de-chantier' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-dispositifs-de-systeme-incendie' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-ascenseurs-escaliers-mecanique-trottoirs-roulantes' ||
    this.$router.currentRoute.value.path == '/controle-d-equipement-de-travail' ||
    this.$router.currentRoute.value.path == '/offre-emplois-details' ||
    this.$router.currentRoute.value.path == '/demande' ||
    this.$router.currentRoute.value.path == '/demande-devis' ||
    this.$router.currentRoute.value.path == '/controle-reglementaire-des-appareils-a-pression-de-gaz-et-vapeur' ||
    this.$router.currentRoute.value.path == '/4-raisons-de-nous-rejoindre' ||
    this.$router.currentRoute.value.path == '/plan-du-site' ||
    this.$router.currentRoute.value.path == '/mentions-legales-2' ||
    this.$router.currentRoute.value.path == '/politique-de-cookies' ||
    this.$router.currentRoute.value.path == '/politique-de-confidentialite'" />

  <!-- Widget  -->
  <WidgetHome v-if="this.$router.currentRoute.value.path == '/'" />

  <CookiesHome v-if="this.$router.currentRoute.value.path == '/' && falgCookies == true" />

  <router-view />

  <DemandeHome  v-if="this.$router.currentRoute.value.path != '/transfer-dashboard'"/>


  <footer class="footer_info" v-if="this.$router.currentRoute.value.path != '/transfer-dashboard'">
    <div class="map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5779.463841041547!2d-7.370628643336544!3d33.69782057769933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7b73d256f309f%3A0x3e969925cc12ea26!2sGTHCONSULT!5e0!3m2!1sfr!2sma!4v1684484901048!5m2!1sfr!2sma"
        height="350" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="question">
      <h3 class="hours">24/7</h3>
      <ul>
        <li>Avez-vous des questions?</li>
        <li class="phone_number"><i class="fa fa-phone" id="phone"></i> <b>+212 5 23 27 27 74</b></li>
      </ul>
    </div>
    <div class="footer_top">
      <ul class="about">
        <li class="footer_list">
          <h5 class="title mt-4 mb-4">A propos</h5>
          <p class="text-justify">GTHCONSULT est un bureau de contrôle agrée par l'etat créé par deux ingénieurs
            pluridisciplinaires.
            Sa mission est d'accompagner les chefs d'établissements et les exploitants pour répondre à leurs obligations
            réglementaires ou volantaires en matière de contôles réglementaires des installationns techniques,
            gestion des risques professionnels,
            protectiion des personnes, des biens et de l'environnement.</p>
        </li>
        <li class="footer_list">
          <h5 class="title">Contactez-Nous</h5>
          <ul class="contact">
            <li><i class="fa fa-building"></i>GTHCONSULT, SIÈGE SOCIAL: BOULEVARD MOHAMMED VI, LOT AL MASSIRA, LOT
              N°187, APPT 5-6 3EME ETAGE,<p class="text">Mohammédia 28810</p>
            </li>
            <li><i class="fa fa-phone" id="phone"></i> <b>+212 5 23 27 27 74</b> </li>
            <li><i class="fa fa-phone"></i> <b>+212 5 23 27 29 79</b></li>
            <li><i class="fa fa-envelope"></i> <b>contact@gthconsult.ma</b></li>
            <li><i class="fa fa-envelope"></i> <b>service.supports@gthconsult.ma</b></li>
            <li><i class="fa fa-clock-o"></i> Temps de travail : 9h00 à 17h00 Lundi au Vendredi</li>
          </ul>
        </li>
        <li class="footer_list">
          <h5 class="title">NEWSLETTER</h5>
          <p>Entrez votre email pour recevoir<br> nos nouvelles</p>
          <p style="color: red;">{{ msgSubscriber }}</p>
          <input type="email" v-model="email" class="email" placeholder="Entrez votre email"
            style="text-align: center" />
          <button class="email_btn" @click="sabonner()">S'ABONNER</button>
        </li>
      </ul>
    </div>
    <div class="footer_bottom">
      <ul class="footer_rights">
        <li><a href="#"><img src="./assets/logo-footer.e772d696.png" width="140px" height="60px"></a></li>
        <li>
          <ol class="copy_right">
            <li>© {{ this.currentDateYear }} Groupe GTHCONSULT </li>
            <li @click="pageMentionsLegales()"> <a href=""> Mentions légales</a></li>
            <li @click="pagePolitiqueConfidentialite()"><a href=""> Politique de confidentialité</a></li>
            <li @click="pagePolitiqueCookies()"><a href=""> Politique Cookies</a></li>
            <li @click="pagePlan()"><a href="">Plan de site</a></li>
          </ol>
        </li>
      </ul>
      <ul class="footer_media">
        <li>
          <ol class="social_media">
            <li><a href="https://twitter.com/gthconsult"><i class="fa fa-twitter" id="twitter"></i> </a></li>
            <li><a href="https://www.linkedin.com/company/gthconsult/"><i class="fa fa-linkedin" id="linkedin"></i> </a>
            </li>
          </ol>
        </li>
      </ul>
    </div>

    <loading v-model:active="isLoading" :can-cancel="true" :on-cancel="onCancel" :is-full-page="fullPage" />

  </footer>


</template>

<script>
import HeaderHome from "@/HeaderHome.vue"
import DemandeHome from "@/DemandeHome.vue"
import WidgetHome from '@/WidgetHome.vue'
import CookiesHome from '@/CookiesHome.vue'
import Subscribers from "@/connection/Subscribers"
import { useCookies } from "vue3-cookies";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

export default {
  name: "App",
  data() {
    return {
      isLoading: false,
      falgCookies: true,
      statusHeader: true,
      currentDateYear: new Date().getFullYear(),
      email: null,
      msgSubscriber: null,
    }
  },
  components: {
    HeaderHome,
    DemandeHome,
    WidgetHome,
    CookiesHome,
    Loading
  },
  methods: {

    sabonner() {
      this.isLoading = true
      Subscribers.envoyer(this.email)
        .then((result) => {
          if (result.data.status == true) {
            this.email = null;
            this.isLoading = false;
          }

          if (result.data.msg == 'Vous êtes déjà abonné') {
            this.email = null;
            this.isLoading = false;
            this.msgSubscriber = "Vous êtes déjà abonné";
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    pageMentionsLegales() {
      this.$router.push({ path: '/mentions-legales-2' })
    },

    pagePolitiqueConfidentialite() {
      this.$router.push({ path: '/politique-de-confidentialite' })
    },

    pagePolitiqueCookies() {
      this.$router.push({ path: '/politique-de-cookies' })
    },

    pagePlan() {
      this.$router.push({ path: '/plan-du-site' })
    }


  },

  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },

  beforeCreate() {

  },

  // created() {
  //   console.log(`the component is now created.`)
  //   // console.log(this.$router.history)
  // },

  mounted() {


    if (Boolean(this.cookies.get("accepteCookies")) === true || Boolean(this.cookies.get("accepteCookies") === false)) {
      this.falgCookies = false;
    }
  },

  // computed:{
  //   currentRoute:{
  //       get(){
  //           this.nombreRuta = this.$route.name;
  //       }
  //   }
  // }



}
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Cairo, Verdana, Tahoma, Arial;
  text-decoration: none;
  list-style: none;
}


.map {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.map iframe {
  border: none;
  border-radius: 10px 10px;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.question {
  width: 85%;
  border: 1px solid rgb(36, 48, 100);
  margin-left: 7.5%;
  border-radius: 5px;
  height: 95px;
  background-color: rgb(36, 48, 100);
}

.question ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.question li {
  padding: 30px 70px;
  color: #fff;
  font-size: 25px;
  font-weight: bold;
}

.footer_top .about {
  display: flex;
  flex-direction: row;
  margin-left: 60px;
  width: 90%;
  margin-bottom: 20px;
}

.hours {
  position: absolute;
  bottom: 1px;
  left: 87%;
  color: #fff;
  font-size: 70px;
  opacity: 0.1;
}

.text {
  padding-left: 20px;
}

.footer_list {
  width: 35%;
  text-align: start;
  padding: 10px;
  padding-left: 60px;
  margin-top: 50px;
  font-size: 15px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: rgb(36, 48, 100);
}

.contact li {
  padding-top: 5px;
}

.email_btn {
  color: #fff;
  background-color: rgb(36, 48, 100);
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 12px;
  width: 78%;
  height: 40px;
  border: 1px solid rgb(36, 48, 100);
  border-radius: 5px;
}

.email {
  margin-bottom: 10px;
  margin-top: 12px;
  width: 78%;
  height: 40px;
  border: 1px solid rgb(36, 48, 100);
  border-radius: 5px;
}

input,
button {
  display: block;
  padding-top: 10px;
}

button:hover {
  color: rgba(255, 255, 255, 0.4)
}

.footer_bottom {
  width: 100%;
  background-color: rgb(36, 48, 100);
  height: 170px;
}

.footer_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

}

.copy_right {
  display: flex;
  flex-direction: row;
  padding: 10px;
  padding-top: 40px;
  padding-left: 40px;
}

.copy_right a {
  padding-left: 10px;
  border-left: 1px solid #fff;
  color: #fff;
  font-size: 11px;
}

.copy_right li {
  padding-right: 10px;
  color: #fff;
  font-size: 11px;
}

.social_media {
  font-size: 30px;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 40%;
}

.social_media li {
  padding: 40px;
}

.social_media a {
  color: #fff;
}

.footer_bottom img {
  margin-top: 30px;
  height: 70px;
  margin-left: 40px;
}

#twitter:hover {
  color: #00acee;
}

#linkedin:hover {
  color: #0072b1;
}








/*responsive Media query */

@media(max-width:600px) and (min-width:0px) {
  .top-bar {
    width: 100%;
    margin-left: 0%;

  }

  .top-bar a.nav-link {

    font-size: 12px;
    margin-left: 0%;

  }

  #catalogue {
    width: 80%;
    padding-left: 10px;
  }

  #Client-space,
  #carrier {
    height: 35px;
    background-color: rgb(207, 31, 33);
    width: 80%;
    padding-left: 10px;
  }

  #icon,
  #icon1 {
    padding: 15px 30px;
  }

  .header-logo,
  .header-logo img {
    float: left;
    top: 35px;
  }

  .header-logo {
    height: 55px;
    width: 200px;
  }

  .header-logo img {
    height: 45px;
    width: 100px;
    padding-bottom: 5px;
  }

  .main-nav-wrap {
    display: block;
    z-index: 6;
    padding-bottom: 12px;

  }

  .main-nav {
    position: absolute;
    top: 90px;
    left: -100%;
    background-color: rgb(238, 242, 247, 0.3);
    margin-top: 0;
    width: 100%;
    display: flex;
    text-align: center;
    transition: all .0s;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .main-nav li {
    transition: all .0s;
    padding: 10px 12px 10px 15px;

    border-bottom: 1px solid #73777a;
    width: 75%;
  }

  .main-nav li:hover {
    border-bottom: none;
    height: fit-content;
  }

  #contact {
    border-bottom: none;
  }

  .main-nav a:hover {
    color: rgb(207, 31, 33, 0.5);
  }

  .main-nav-wrap .bar {
    display: block;
    position: absolute;
    top: 35px;
    right: 90px;
    cursor: pointer;
  }


  #check:checked~div .main-nav {
    left: 0;
    height: max-content;
  }

  .map iframe {
    width: 98%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .question {
    width: 90%;
    height: 160px;
    margin: 5%;
  }

  .question ul {
    display: flex;
    flex-direction: column;
    padding: 0%;
    padding-top: 10%;
  }

  .question b {
    font-size: 14px;
  }

  .question li {
    font-size: 16px;
    padding: 10px 50px;
    text-align: center;
  }

  .hours {
    position: absolute;
    bottom: 1px;
    left: 30%;
    color: #fff;
    font-size: 70px;
    opacity: 0.1;
  }

  .footer_top .about {
    display: flex;
    flex-direction: column;
    margin-left: -30px;

  }

  .footer_top .about li {
    margin-top: 0%;
    width: 90%;
  }

  input,
  button {
    width: 100%;
  }

  .footer_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 370px;
    position: relative;
  }

  .footer_bottom .footer_rights {
    position: absolute;
    top: 90px;
    width: 90%;
  }

  .copy_right {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 7%;
  }

  .copy_right li,
  .copy_right a {
    font-size: 16px;
    border: none;
  }

  .footer_bottom img {
    margin-left: 36%;
  }

  .social_media {
    position: absolute;
    left: 37%;
  }

  .social_media li {
    padding-left: 0%;
  }
}

@media(max-width:900px) {
  footer {
    margin-top: 10px;
  }

  .map iframe {
    width: 98%;
    padding-left: 20px;
    padding-right: 5px;
  }

  .question {
    width: 90%;
    height: 160px;
    margin: 5%;
  }

  .question ul {
    display: flex;
    flex-direction: column;
    padding: 0%;
    padding-top: 5%;
  }

  .phone_number p {
    font-size: 10px;
  }

  .question li {
    font-size: 10px;
    padding: 10px 50px;
    text-align: center;
  }

  .hours {
    position: absolute;
    bottom: 1px;
    left: 30%;
    color: #fff;
    font-size: 70px;
    opacity: 0.1;
  }

  .footer_top .about {
    display: flex;
    flex-direction: column;
    margin-left: -30px;

  }

  .footer_top .about li {
    margin-top: 0%;
    width: 90%;
  }

  input,
  button {
    width: 100%;
  }

  .footer_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 370px;
    position: relative;
  }

  .footer_bottom .footer_rights {
    position: absolute;
    top: 90px;
    width: 90%;
  }

  .copy_right {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 10%;
  }

  .copy_right li,
  .copy_right a {
    font-size: 16px;
    border: none;
  }

  .footer_bottom img {
    margin-left: 45%;
  }

  .social_media {
    position: absolute;
    left: 45%;
  }

  .social_media li {
    padding-left: 0%;
  }
}

@media(max-width:1100px) {
  footer {
    margin-top: 30px;
  }

  .question {
    width: 90%;
    height: 160px;
    margin: 5%;
  }

  .question ul {
    display: flex;
    flex-direction: column;
    padding: 0%;
    padding-top: 25px;
  }

  .phone_number P {
    font-size: 30px;
  }

  .question li {
    font-size: 20px;
    padding: 10px 50px;
    text-align: center;
  }

  .hours {
    position: absolute;
    bottom: 1px;
    left: 30%;
    color: #fff;
    font-size: 70px;
    opacity: 0.1;
  }

  .footer_top .about {
    display: flex;
    flex-direction: column;
    margin-left: -30px;

  }

  .footer_top .about li {
    margin-top: 0%;
    width: 90%;
  }

  input,
  button {
    width: 100%;
  }

  .footer_bottom {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 370px;
    position: relative;
  }

  .footer_bottom .footer_rights {
    position: absolute;
    top: 90px;
    width: 90%;
  }

  .copy_right {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 10%;
  }

  .copy_right li,
  .copy_right a {
    font-size: 16px;
    border: none;
  }

  .footer_bottom img {
    margin-left: 45%;
  }

  .social_media {
    position: absolute;
    left: 45%;
  }

  .social_media li {
    padding-left: 0%;
  }
}

@media(max-width:1100px) and (min-width:600px) {
  nav.main-nav-wrap ul {
    padding-right: 0px;
  }

  .top-bar a.nav-link {

    font-size: 14px;
    margin-right: 30px;

  }

  #catalogue {
    width: 80%;

  }

  #Client-space,
  #carrier {
    height: 35px;
    background-color: rgb(207, 31, 33);
    width: 80%;

  }



  #icon,
  #icon1 {

    padding: 15px 30px;
  }

  .header-logo,
  .header-logo img {
    float: left;
    top: 35px;
  }

  .header-logo {
    height: 55px;
    width: 200px;
  }

  .header-logo img {
    height: 45px;
    width: 100px;
    padding-bottom: 5px;
  }

  .main-nav-wrap {
    display: block;
    z-index: 6;
    padding-bottom: 12px;

  }

  .main-nav {
    position: absolute;
    top: 90px;
    left: -100%;
    background-color: rgb(238, 242, 247, 0.3);
    margin-top: 0;
    width: 100%;
    display: flex;
    text-align: center;
    transition: all .0s;
    flex-direction: column;
    padding-bottom: 50px;
    padding-top: 50px;
  }

  .main-nav li {
    transition: all .0s;
    padding: 10px 12px 10px 15px;
    border-bottom: 1px solid #73777a;
    width: 75%;
  }

  .main-nav li:hover {
    border-bottom: none;
    height: fit-content;
  }

  .main-nav-wrap .bar {
    display: block;
    position: absolute;
    top: 35px;
    right: 90px;
    cursor: pointer;
  }


  #check:checked~div .main-nav {
    left: 0;
    height: max-content;
  }


}
</style>
